const dictionary = {
    pt: {
        errorLabelText: 'Erro',
        connectionTimeoutHeaderText: 'Tempo de conexão esgotado',
        connectionTimeoutMessageText: 'A conexão foi interrompida.',
        timeoutHeaderText: 'Tempo esgotado',
        timeoutMessageText:
            'O rosto não ficou dentro do oval no limite de tempo. Tente novamente e preencha completamente o oval com o rosto.',
        faceDistanceHeaderText: 'Movimento para frente detectado',
        faceDistanceMessageText: 'Evite se mover mais próximo durante a conexão.',
        multipleFacesHeaderText: 'Múltiplos rostos detectados',
        multipleFacesMessageText:
            'Certifique-se de que apenas um rosto esteja presente na frente da câmera durante a conexão.',
        clientHeaderText: 'Erro no cliente',
        clientMessageText: 'A verificação falhou devido a um problema no cliente.',
        serverHeaderText: 'Problema no servidor',
        serverMessageText: 'Não foi possível concluir a verificação devido a um problema no servidor.',
        landscapeHeaderText: 'Orientação paisagem não suportada',
        landscapeMessageText:
            'Gire seu dispositivo para a orientação retrato (vertical).',
        portraitMessageText:
            'Certifique-se de que seu dispositivo permaneça na orientação retrato (vertical) durante toda a verificação.',
        tryAgainText: 'Tente novamente',
        cameraMinSpecificationsHeadingText:
            'A câmera não atende às especificações mínimas',
        cameraMinSpecificationsMessageText:
            'A câmera deve suportar no mínimo resolução de 320x240 e 15 quadros por segundo.',
        cameraNotFoundHeadingText: 'Câmera inacessível',
        cameraNotFoundMessageText:
            'Verifique se uma câmera está conectada e se não há outro aplicativo usando a câmera. Pode ser necessário ir às configurações para conceder permissões de câmera e fechar todas as instâncias do navegador antes de tentar novamente.',
        a11yVideoLabelText: 'Webcam para verificação de liveness',
        cancelLivenessCheckText: 'Cancelar verificação de liveness',
        goodFitCaptionText: 'Encaixe perfeito',
        goodFitAltText:
            "Ilustração de um rosto encaixado perfeitamente dentro de um oval.",
        hintCenterFaceText: 'Centralize seu rosto',
        hintCenterFaceInstructionText:
            'Instrução: Antes de iniciar a verificação, certifique-se de que sua câmera esteja no centro superior da tela e centralize seu rosto para a câmera. Quando a verificação começar, um oval aparecerá no centro. Você será instruído a se mover para dentro do oval e depois a ficar parado. Após alguns segundos de imobilidade, você deverá ouvir que a verificação foi concluída.',
        hintFaceOffCenterText:
            'O rosto não está no oval, centralize seu rosto na câmera.',
        hintMoveFaceFrontOfCameraText: 'Mova o rosto para a frente da câmera',
        hintTooManyFacesText: 'Certifique-se de que apenas um rosto esteja na frente da câmera',
        hintFaceDetectedText: 'Rosto detectado',
        hintCanNotIdentifyText: 'Mova o rosto para a frente da câmera',
        hintTooCloseText: 'Afaste-se',
        hintTooFarText: 'Aproxime-se',
        hintConnectingText: 'Conectando...',
        hintVerifyingText: 'Verificando...',
        hintCheckCompleteText: 'Verificação concluída',
        hintIlluminationTooBrightText: 'Mova-se para um local com menos luz',
        hintIlluminationTooDarkText: 'Mova-se para um local mais iluminado',
        hintIlluminationNormalText: 'Condições de iluminação normais',
        hintHoldFaceForFreshnessText: 'Fique imóvel',
        hintMatchIndicatorText: '50% concluído. Continue se movendo para mais perto.',
        photosensitivityWarningBodyText:
            'Esta verificação exibe diferentes cores. Tenha cuidado se você for sensível à luz.',
        photosensitivityWarningHeadingText: 'Aviso de Fotosensibilidade',
        photosensitivityWarningInfoText:
            'Algumas pessoas podem ter convulsões epilépticas quando expostas a luzes coloridas. Tenha cuidado se você, ou alguém da sua família, tiver uma condição epiléptica.',
        photosensitivityWarningLabelText: 'Mais informações sobre fotosensibilidade',
        photosensitivyWarningBodyText:
            'Esta verificação exibe diferentes cores. Tenha cuidado se você for sensível à luz.',
        photosensitivyWarningHeadingText: 'Aviso de Fotosensibilidade',
        photosensitivyWarningInfoText:
            'Algumas pessoas podem ter convulsões epilépticas quando expostas a luzes coloridas. Tenha cuidado se você, ou alguém da sua família, tiver uma condição epiléptica.',
        photosensitivyWarningLabelText: 'Mais informações sobre fotosensibilidade',
        retryCameraPermissionsText: 'Tentar novamente',
        recordingIndicatorText: 'Gravando',
        startScreenBeginCheckText: 'Iniciar verificação de vídeo',
        tooFarCaptionText: 'Muito longe',
        tooFarAltText:
            "Ilustração de um rosto dentro de um oval, com um espaço entre o perímetro do rosto e as bordas do oval.",
        waitingCameraPermissionText: 'Aguardando permissão para acessar a câmera.',
    },
};

export default dictionary;