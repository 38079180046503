import React, { useState, useEffect } from 'react';
import { FaceLivenessDetector } from '@aws-amplify/ui-react-liveness';
import { Loader } from '@aws-amplify/ui-react';
import dictionary from './portugueseInstructions';
import './Liveness.css';

export function LivenessQuickStartReact() {
    const [loading, setLoading] = useState(true);
    const [createLivenessApiData, setCreateLivenessApiData] = useState(null);

    useEffect(() => {

        const queryParams = new URLSearchParams(window.location.search);
        const sessionId = queryParams.get('sid');
        setCreateLivenessApiData({ sessionId });
        setLoading(false);
    }, []);


    const handleAnalysisComplete = async () => {

        window.parent.postMessage({
            'message' : 'pv-ok',
        }, '*');
      
    };

    return (
        <div className="custom-container">
            {loading ? (
                <div className="custom-loader">
                    <Loader />
                </div>
            ) : (
                <><FaceLivenessDetector
                        sessionId={createLivenessApiData.sessionId}
                        region="us-east-1"
                        onAnalysisComplete={handleAnalysisComplete}
                        displayText={dictionary['pt']}
                        onError={(error) => {
                            console.error(error);
                        } } /></>
            )}
        </div>
    );
}
